//import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'


export default new Vuex.Store({
  plugins:[createPersistedState()],
  state: {
    /*day:null,
    isPin:true,
    user:{
      myid:crypto.randomUUID(),
      userName:"",
      archives:[],
      frameName:"",
      isLogin:false,
      icon:"",
      intro:"",
      isPurchase:false,
      plan:0,
      updated:"",
      isAcount:false,
    },*/
  },
  getters:{
    /*day(state){
      return state.day;
    },
    isPin(state){
      return state.isPin;
    },
    user(state){
      return state.user;
    },*/
  },
  mutations: {
    /*setDay(state, payload) {
      state.day = payload.day;
    },
    setIsPin(state,payload){
      state.isPin = payload.isPin;
    },
    setUser(state,payload){
      state.user = payload.user;
    },*/
  },
  actions:{
    /*setDay({commit},day){
      commit("setDay",{day});
    },
    setIsPin({commit},isPin){
      commit("setIsPin",{isPin});
    },
    setUser({commit},user){
      commit("setUser",{user});
    },*/
  }
})