//import Vue from 'vue'
import { createRouter, createWebHistory } from "vue-router"

import Reservation from "./views/Reservation.vue"
import History from "./views/History.vue"


const router = createRouter({
  history:createWebHistory(),
  //base:process.env.BASE_URL,
  mode:"hash",
  routes:[
    { path:process.env.BASE_URL+"/",name:"Reservation",component:Reservation },
    { path:"/history",name:"History",component:History },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } 
    else {
      return { top: 0 }
    }
  },
})

export default router
//Redirect permanent /index.html https://pakara-keiba.com/info/