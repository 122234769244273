<template>
  <div class="w-100 pa-2 mb-1" :class="getHistBack(history.isEnd)" style="min-height:50px;border:1px solid #c6c6c6;border-radius:12px;">
    <h3>{{ history.date }}</h3>
    <p>
      <span v-for="menu in history.menu" :key="menu">
        {{ menu }},
      </span>
    </p>
  </div>
</template>

<script>


export default {
  name: "History-",
  props:{
    history:{
      default:[],
    }
  },
  components: {
    
  },
  computed:{
    getHistBack(){
      return (isEnd) => {
        if(isEnd){
          return "";
        }
        else{
          return "future";
        }
      }
    },
  },
}
</script>

<style scoped>

.future{
  background-color:#cceae7;
}

</style>