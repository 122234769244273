<template>
  <div @click.stop.prevent="clickList" class="d-flex w-100 flex-row pa-2 rounded-lg border" :style="getStyle(nomination.isSelect)" >
    <div>
      <v-avatar size="40" color="#9E9E9E">
        <v-img :src="getIcon(nomination.icon)"></v-img>
      </v-avatar>
    </div>
    <div class="ml-2 align-self-center">
      <h4 class="">{{ nomination.name }}</h4>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Nomination-',
  emits: ['clickList'],
  props:{
    nomination:{
      default:{},
    },
  },
  components: {

  },
  data: () => ({

  }),
  created:function(){

  },
  methods:{
    clickList(){
      this.$emit("clickList");
    },
    getIcon(icon){
      if(icon == ""){
        return require("@/assets/image/person.png");
      }
      else{
        //return require("@/assets/image/"+icon);
        return process.env.VUE_APP_IMAGE_ROOT+icon;
      }
    }
  },
  computed:{
    getStyle(){
      return (isSelect) => {
        if(isSelect)return { "background-color": "#dfdfdf" };
        else return {};
      };
    }
  },
}
</script>

<style scoped>
  .border{
    border:1px solid #819c9c9c;
    transition: all 0.2s;
  }
</style>